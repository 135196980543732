<template>
  <v-container fluid  >
    <v-layout row wrap full-height justify-center>
       <v-flex xs12 sm4 pa-3>
        <v-card flat tile color="grey lighten-4" height="100%" >
          <v-card-title class="pb-0">
            <v-layout justify-center>
              <v-img :src="logo" :lazy-src="logo" max-width="150"/>
            </v-layout>
          </v-card-title>

          <v-card-text class="pa-3">
            <v-form v-model="valid_password" >

               <p class="mb-0"> Ingresa tu nueva contraseña</p>
              <v-text-field
                v-model="recover.password"
                solo
                min="8"
                counter
                :append-icon="hide_password ? 'visibility' : 'visibility_off'"
                @click:append="() => (hide_password = !hide_password)"
                :type="hide_password ? 'password' : 'text'"
                required
                class="hidden-shadow"
                :rules="g_password_rules"
                :disabled="g_isLoading"
              ></v-text-field>



              <v-layout column align-center>
            <v-btn
              depressed
              color="primary"
              class="lowercase"
              :loading="g_isLoading"
              :disabled="!valid_password"
              @click="recoverPassword"
            >Guardar Contraseña</v-btn>
               </v-layout>

            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <loading-dialog :isLoading="g_isLoading"></loading-dialog>
    <message-dialog :dialog="g_dialog"></message-dialog>
  </v-container>
</template>

<script>
  export default {
    name: 'ResetPassword',
    metaInfo: {
      title: 'Recuperar Contraseña',
      meta: [
        { vmid: 'og:title', property: 'og:title', content: 'Recuperar Contraseña' },
        { vmid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: 'Recuperar Contraseña' }
      ]
    },
    data () {
      return {
        recover: {
          password: '',
          token: this.$route.params.token
        },
        valid_password: false,
        hide_password: true,
        logo: require('../assets/img/brand/logo.png')
      }
    },
    methods: {
      redirect () {
        this.$router.push('/iniciar_sesion')
      },
      recoverPassword () {
        this.g_isLoading = true
        let payload = {
          password: this.recover.password,
          token: this.$route.params.token
        }
        this.$api.recoverPassword(
          payload,
          response => {
            this.g_dialog = {
              title: 'Éxito',
              message: 'Tu contraseña ha sido actualizada',
              show: true,
              hasAction: true,
              action: this.redirect.bind(this)
            }
            this.g_isLoading = false
          },
          response => this.$errorHandling(response).then(result => {
            this.recoverPassword()
          }, result => {})
        )
      }
    }
  }
</script>

<style scoped>

</style>
